import L from "leaflet";

type MarkerTypes = "Circle" | "Square" | "Triangle" | "Inverted Triangle" | "Star"

type CreateSettingsMarker = Record<MarkerTypes, MarkerMaker>
type MarkerMaker = (settings: MarkerSettings) => MarkerShape
interface MarkerSettings {
  opacity?: number,
  color?: string,
}
interface MarkerShape {
  html: string
}

const circleMarker = ({color, opacity}: MarkerSettings): MarkerShape => {
  return (
    {
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -34 36 36">
          <path d="M 0 -16 A 1 1 0 0 0 32 -16 A 1 1 0 0 0 0 -16" fill-opacity=${opacity} stroke-opacity=${opacity} fill=${color} stroke=${color} stroke-width="3" />
        </svg>`,
    }
  )
}

const squareMarker = ({color, opacity}: MarkerSettings): MarkerShape => {
  return (
    {
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 32 32">
          <path d="M 0 0 L 0 -32 L 32 -32 L 32 0 L 0 0" fill-opacity=${opacity} stroke-opacity=${opacity} fill=${color} stroke=${color} stroke-width="3" />
        </svg>`,
    }
  )
}
const triangleMarker = ({color, opacity}: MarkerSettings): MarkerShape => {
  return (
    {
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 32 32" >
          <path d="M 0 0 L 32 0 L 16 -32 L 0 0" fill-opacity=${opacity} stroke-opacity=${opacity} fill=${color} stroke=${color} stroke-width="3" />
        </svg>`,
    }
  )
}
const triangleInvertedMarker = ({color, opacity}: MarkerSettings): MarkerShape => {
  return (
    {
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 32 32" >
          <path d="M 32 -32 L 0 -32 L 16 0 L 32 -32" fill-opacity=${opacity} stroke-opacity=${opacity} fill=${color} stroke=${color} stroke-width="3" />
        </svg>`,
    }
  )
}
const starMarker = ({color, opacity}: MarkerSettings): MarkerShape => {
  return (
    {
      html: `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g id="surface1">
            <path d="M 16 4.589844 L 18.832031 13.308594 L 28 13.308594 L 20.585938 18.695312 L 23.414062 27.414062 L 16 22.023438 L 8.582031 27.410156 L 11.414062 18.691406 L 4 13.308594 L 13.167969 13.308594 Z M 16 4.589844 "
            fill-opacity=${opacity} stroke-opacity=${opacity} fill=${color} stroke=${color} stroke-width="3"
            />
          </g>
        </svg>`
    }
  )
}

const createSettingsMarker: CreateSettingsMarker = {
  Circle: circleMarker,
  Square: squareMarker,
  Triangle: triangleMarker,
  "Inverted Triangle": triangleInvertedMarker,
  Star: starMarker,
}

interface MarkerType {
  markerColor?: string,
  markerOpacity?: number,
  radius?: number,
  markerType?: MarkerTypes,
}

const MarkerType = ({markerColor, markerOpacity, radius, markerType}: MarkerType) => {
  const size = radius ? radius * 2 : 20;
  const marker = markerType ? markerType : "Circle";
  const color = markerColor ? markerColor : "blue";
  const opacity = markerOpacity ? markerOpacity : 1;
  const markerTypeHtml = createSettingsMarker[marker]({opacity, color});
  return  (
    L.divIcon({
      ...markerTypeHtml,
      className: `border: none`,
      iconSize: [size, size],
      iconAnchor: [0, 0],
    })
  );
}
export default MarkerType;
