import { alpha } from "metabase/lib/colors";
import { getColorScale, getSafeColor } from "metabase/lib/colors/scales";

import {
  extent,
  getColumnIndexesByName,
  OPERATOR_FORMATTER_FACTORIES,
} from "./table_format";

const GRADIENT_ALPHA = 0.75;

export function makeCircleSettingsGetter(rows, cols, formattingSettings) {
  let formatters = [];
  const colIndexes = getColumnIndexesByName(cols);

  try {
    const columnExtents = computeColumnExtents(
      formattingSettings,
      rows,
      colIndexes,
    );
    formatters = compileFormatters(
      formattingSettings,
      columnExtents,
      colIndexes,
    );
  } catch (e) {
    console.error("Unexpected error compiling column formatters: ", e);
  }
  if (formatters.length === 0) {
    return () => null;
  } else {
    return function (row) {
      let color = null;
      let opacity = null;
      let marker = null;

      try {
        for (let i = 0; i < formatters.length; i++) {
          const formatter = formatters[i];
          const computedStyles = formatter(row);
          const { color: markerColor, opacity: markerOpacity, marker: markerType } = computedStyles
          if (markerColor != null) {
            color = markerColor;
          }
          if (markerOpacity != null) {
            opacity = markerOpacity/100;
          }
          if (markerType != null) {
            marker = markerType;
          }
        }
        return {color, opacity, marker};
      } catch (error) {
        console.error("Get color ERROR\n", error);
      }
      return null;
    };
  }
}

function compileCircleStyleFormatter(format, columnExtents, colIndexes) {
  const { operator, value, color, targetColumn, point_opacity, marker_type } = format;
  const targetColIndexToCompare = colIndexes?.[targetColumn];
  if (format.type === "single") {
    const formatterFactory = OPERATOR_FORMATTER_FACTORIES[operator];

    if (formatterFactory) {
      return row => {
        const rowValue = row[targetColIndexToCompare];
        const isRowValueMatchesCondition = formatterFactory(value)(rowValue);
        return isRowValueMatchesCondition ? {color, opacity: point_opacity, marker: marker_type} : {};
      };
    } else {
      return () => null;
    }
  }

  if (format.type === "range") {
    const columnMin = colName =>
      columnExtents && columnExtents[colName] && columnExtents[colName][0];
    const columnMax = colName =>
      columnExtents && columnExtents[colName] && columnExtents[colName][1];
    const min =
      format.min_type === "custom"
        ? parseFloat(format.min_value)
        : format.min_type === "all"
        ? Math.min(...columnMin(targetColumn))
        : columnMin(targetColumn);
    const max =
      format.max_type === "custom"
        ? parseFloat(format.max_value)
        : format.max_type === "all"
        ? Math.max(...columnMax(targetColumn))
        : columnMax(targetColumn);
    if (typeof max !== "number" || typeof min !== "number") {
      console.warn("Invalid range min/max", min, max);
      return () => null;
    }
    const scale = getColorScale(
      [min, max],
      format.colors.map(c => alpha(c, GRADIENT_ALPHA)),
    ).clamp(true);

    return row => {
      const value = row[targetColIndexToCompare];
      const colorValue = scale(value);
      if (!colorValue) {
        return {};
      }
      return {color: getSafeColor(colorValue), marker: marker_type}
    };
  }

  return () => null;
}

function compileFormatters(formats, columnExtents, colIndexes) {
  const formatters = [];

  formats.forEach(format => {
    formatters.push(
      compileCircleStyleFormatter(format, columnExtents, colIndexes),
    );
  });

  return formatters;
}

function computeColumnExtents(formats, rows, colIndexes) {
  const columnExtents = {};

  formats.forEach(format => {
    const { targetColumn } = format;

    const colIndex = colIndexes?.[targetColumn];
    if (!columnExtents[targetColumn]) {
      columnExtents[targetColumn] = extent(rows, colIndex);
    }
  });

  return columnExtents;
}
