var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Nga,nD,rD,DD;
$CLJS.cD=function(a,b){return function(){function c(l,m,t){return $CLJS.zd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.zd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.zd(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var C=Array(arguments.length-3);A<C.length;)C[A]=arguments[A+3],++A;A=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.zd(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Le(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):A}())}l.A=3;l.B=function(t){var u=$CLJS.z(t);t=$CLJS.B(t);var v=$CLJS.z(t);t=$CLJS.B(t);var x=$CLJS.z(t);t=$CLJS.Hc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.dD=function(a){return $CLJS.fk.g($CLJS.cf.h(a),$CLJS.qB)};$CLJS.eD=function(a,b){a=$CLJS.Ns($CLJS.Gz(a,/''/,"'"),/\{\d+\}/);return $CLJS.Ne.j($CLJS.Gs.t,$CLJS.zk(a),$CLJS.zk(b))};$CLJS.fD=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.gD=new $CLJS.M("metabase.lib.schema.id","metric","metabase.lib.schema.id/metric",369478774);
$CLJS.hD=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.iD=new $CLJS.M("metabase.lib.schema.id","segment","metabase.lib.schema.id/segment",-983297351);$CLJS.jD=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.kD=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.lD=new $CLJS.M(null,"display-name","display-name",694513143);Nga=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);
$CLJS.mD=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);nD=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.oD=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.pD=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);
$CLJS.qD=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);rD=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.sD=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.tD=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.uD=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.vD=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.wD=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.xD=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.yD=new $CLJS.M(null,"x","x",2099068185);
$CLJS.zD=new $CLJS.M("metabase.lib.schema.id","field","metabase.lib.schema.id/field",-985236431);$CLJS.AD=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.BD=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.CD=new $CLJS.M(null,"strategy","strategy",-1471631918);DD=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.Y($CLJS.qD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,new $CLJS.h(null,1,[$CLJS.si,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"non-blank string"],null),$CLJS.Oe($CLJS.Hz)],null)],null));$CLJS.Y($CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pr,new $CLJS.h(null,1,[$CLJS.si,0],null)],null));$CLJS.Y($CLJS.hD,$CLJS.ll);
$CLJS.Y($CLJS.wD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"positive number"],null),$CLJS.cD($CLJS.Yk,$CLJS.ql)],null));$CLJS.Y(DD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,new $CLJS.h(null,2,[$CLJS.si,36,$CLJS.Aj,36],null)],null));
$CLJS.Y(rD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid semantic type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return["Not a valid semantic type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.Xi)}],null));
$CLJS.Y(nD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid relation type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return["Not a valid relation type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.$h)}],null));$CLJS.Y($CLJS.mD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nD],null)],null));
$CLJS.Y($CLJS.AD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,2,[$CLJS.ts,"valid base type",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.ej);return["Not a valid base type: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.Dz(a,$CLJS.cj)}],null));
$CLJS.Y($CLJS.vD,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xD,DD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.AD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.AD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rj,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,
$CLJS.mD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Nga,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.oD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.uj,$CLJS.Gi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bj,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.vD],null)],null));