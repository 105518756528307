/* eslint-disable react/prop-types */
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useContext } from "react";

import { color } from "metabase/lib/colors";
import MarkerType from "metabase/visualizations/components/CreateMarker";
import { computeMinimalBounds } from "metabase/visualizations/lib/mapping";
import {
  getBackgroundImageSettings,
  getBackgroundImageSettingsForDragAndDrop,
} from "metabase/visualizations/shared/utils/backgroundImageSettings";

import CardRenderer from "./CardRenderer";
import { ChartSettingsContext } from "./ChartSettings";

const BLOCK_WITH_POINTS_ID_LOCAL = "BLOCK_WITH_POINTS_ID_LOCAL";
const BLOCK_WITH_BG_IMAGE_ID_LOCAL = "BLOCK_WITH_BG_IMAGE_ID_LOCAL";
const BLOCK_WRAPPER_ID_LOCAL = "BLOCK_WRAPPER_ID_LOCAL";
const EMPTY_FUNCTION = () => {};

const LeafletChoropleth = ({
  series = [],
  geoJson,
  minimalBounds = computeMinimalBounds(geoJson.features || [geoJson]),
  getColor = () => color("brand"),
  onHoverFeature = () => {},
  onClickFeature = () => {},
  onRenderError = () => {},
  getRadius = () => {
    return 300;
  },
  markerSettingsGetter = () => {},
  setStateMap = undefined,
}) => {
  const polygonsAll = geoJson.features.map(el => el.geometry.coordinates);
  const leafPolygons = polygonsAll.map(el => L.polygon(el));
  const getCentersOfPolygons = leafPolygons.map(el =>
    el.getBounds().getCenter(),
  );

  const settingsContext = useContext(ChartSettingsContext);

  const setElementWithSvgRef =
    settingsContext?.setElementWithSvgRef || EMPTY_FUNCTION;
  const blockWithPointsId =
    settingsContext?.blockWithPointsId || BLOCK_WITH_POINTS_ID_LOCAL;
  const blockWithBgImageId =
    settingsContext?.blockWithBgImageId || BLOCK_WITH_BG_IMAGE_ID_LOCAL;
  const blockWrapperId =
    settingsContext?.blockWrapperId || BLOCK_WRAPPER_ID_LOCAL;

  const settings = series[0]?.card?.visualization_settings || {};

  const colorMarkerFromSettings =
    settings?.["map.color_circle"] ?? color("accent1");

  const getShowMarker = settings ? settings["map.show_circle"] || false : false;

  const backgrounImageSettings = getBackgroundImageSettings(settings);

  const isBindToObjectMode =
    settings["card.background_image_bind_to_object"] || false;
  const isDragAndDropMode =
    settings["card.background_image_drag_and_drop"] || false;

  const pointsRadius = settings["card.background_map_points_radius"] || 1;
  const pointsColor =
    settings["card.background_map_points_color"] || color("accent1");

  const backgroundImageSnapToPoint =
    settings["card.background_image_snap_to_point"];

  const backgroundImageOverCardSettings =
    settings["card.background_image_over_card"] ?? true;
  const backgroundImageZIndex =
    backgroundImageOverCardSettings ||
    (backgroundImageSnapToPoint && settingsContext)
      ? 2
      : 0;

  const makeMarker = ({ coordinates, featureId, radiusScale }) => {
    const feature = geoJson.features[featureId];

    if (!getRadius(feature)) {
      return null;
    }

    const radius = getRadius(feature) / radiusScale || 5;

    const markerConditionalSettings = markerSettingsGetter(feature);
    const markerOpacity = markerConditionalSettings
      ? markerConditionalSettings.opacity
      : 1;
    const markerType = markerConditionalSettings?.marker
      ? markerConditionalSettings.marker
      : "Circle";

    const markerColor = markerConditionalSettings
      ? markerConditionalSettings.color
      : colorMarkerFromSettings;

    const marker = L.marker([coordinates.lng, coordinates.lat], {
      icon: MarkerType({ markerColor, markerOpacity, radius, markerType }),
    });

    marker.on({
      mousemove: e => {
        onHoverFeature({
          feature: feature,
          event: e.originalEvent,
        });
      },
      mouseout: e => {
        onHoverFeature(null);
      },
      click: e => {
        onClickFeature({
          feature: feature,
          event: e.originalEvent,
        });
      },
    });

    return marker;
  };

  const makeMarkers = radiusScale =>
    getShowMarker
      ? [
          ...getCentersOfPolygons.map((coordinates, featureId) =>
            makeMarker({ coordinates, featureId, radiusScale }),
          ),
        ].filter(el => !!el)
      : [];

  const makePointsOfPolygon = polygon => {
    const pointsList = [];
    const polygonsLatLngs = polygon.getLatLngs();

    const traversePolygons = polygons => {
      polygons.forEach(element => {
        if (Array.isArray(element)) {
          traversePolygons(element);
        } else if (
          typeof element === "object" &&
          element !== null &&
          "lng" in element &&
          "lat" in element
        ) {
          const circle = L.circleMarker([element.lng, element.lat], {
            color: pointsColor,
            radius: pointsRadius,
          });
          circle.on({
            click: e => {
              const parentElement = document.getElementById(blockWithPointsId);
              const parentRect = parentElement.getBoundingClientRect();

              const pointRect = e.originalEvent.target.getBoundingClientRect();
              const x = pointsRadius + pointRect.left - parentRect.left;
              const y = pointsRadius + pointRect.top - parentRect.top;

              if (settingsContext.setSelectedPoint) {
                settingsContext.setSelectedPoint({ x, y });
              }
            },
          });
          pointsList.push(circle);
        }
      });
    };

    traversePolygons(polygonsLatLngs);

    return pointsList;
  };

  const pointsOfMap = backgroundImageSnapToPoint
    ? leafPolygons.map((polygon, id) => makePointsOfPolygon(polygon, id)).flat()
    : [];

  return (
    <CardRenderer
      card={{ display: "map" }}
      series={series}
      className="spread"
      renderer={(element, props) => {
        element.className = "spread";
        element.style.backgroundColor = "transparent";
        element.style.overflow = "visible";
        element.style.zIndex = 1;

        const elementParent = element.parentNode;
        setElementWithSvgRef(element);

        const defaultMapOptions = {
          attributionControl: false,
          fadeAnimation: false,
          markerZoomAnimation: false,
          trackResize: true,
          worldCopyJump: true,
          zoomAnimation: false,
          zoomSnap: 0,

          // disable zoom controls
          dragging: false,
          tap: false,
          zoomControl: false,
          touchZoom: false,
          doubleClickZoom: false,
          scrollWheelZoom: false,
          boxZoom: false,
          keyboard: false,
        };

        const map = L.map(element, defaultMapOptions);

        const style = feature => {
          return {
            fillColor: getColor(feature),
            weight: 1,
            opacity: 1,
            color: "white",
            fillOpacity: 1,
          };
        };

        const onEachFeature = (feature, layer) => {
          layer.on({
            mousemove: e => {
              onHoverFeature({
                feature,
                event: e.originalEvent,
              });
            },
            mouseout: e => {
              onHoverFeature(null);
            },
            click: e => {
              onClickFeature({
                feature,
                event: e.originalEvent,
              });
            },
          });
        };

        // main layer
        L.featureGroup([
          L.geoJson(geoJson, {
            style,
            onEachFeature,
          }),
        ]).addTo(map);

        map.fitBounds(minimalBounds);

        const zoom = map.getZoom();
        const lat = map.getCenter().lat;
        const metersPerPx =
          (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);

        const markers = makeMarkers(metersPerPx);
        L.featureGroup(markers).addTo(map);

        if (setStateMap) {
          setStateMap(metersPerPx);
        }

        if (isBindToObjectMode || isDragAndDropMode) {
          const mapSvg = element.querySelector("g");
          const bbox = mapSvg.getBBox();
          const svgWidth = bbox.width;
          const svgHeight = bbox.height;
          const blockWidth = element.offsetWidth;
          const blockHeight = element.offsetHeight;

          const topOffset = `${(blockHeight - svgHeight) / 2}px`;
          const leftOffset = `${(blockWidth - svgWidth) / 2}px`;

          const blockWrapper = document.createElement("div");
          blockWrapper.id = blockWrapperId;
          blockWrapper.style.backgroundColor = "transparent";
          blockWrapper.style.overflow = "visible";
          blockWrapper.style.height = svgHeight + "px";
          blockWrapper.style.width = svgWidth + "px";
          blockWrapper.style.position = "relative";
          blockWrapper.style.top = topOffset;
          blockWrapper.style.left = leftOffset;
          blockWrapper.style.zIndex = backgroundImageZIndex;

          const blockWithBackgroundImage = document.createElement("div");
          blockWithBackgroundImage.id = blockWithBgImageId;

          blockWithBackgroundImage.style.backgroundColor = "transparent";
          blockWithBackgroundImage.style.overflow = "visible";
          blockWithBackgroundImage.style.height = svgHeight + "px";
          blockWithBackgroundImage.style.width = svgWidth + "px";
          blockWithBackgroundImage.style.position = "absolute";
          blockWithBackgroundImage.style.zIndex = 2;

          if (isDragAndDropMode && !settingsContext) {
            const imageContainer = document.createElement("div");

            const dragAndDropSettings =
              getBackgroundImageSettingsForDragAndDrop(settings);

            imageContainer.style.width = "100%";
            imageContainer.style.height = "100%";
            imageContainer.style.backgroundImage =
              backgrounImageSettings.backgroundImage;
            imageContainer.style.backgroundRepeat =
              backgrounImageSettings.backgroundRepeat;
            imageContainer.style.backgroundAttachment =
              backgrounImageSettings.backgroundAttachment;
            imageContainer.style.backgroundPosition =
              backgrounImageSettings.backgroundPosition;
            imageContainer.style.backgroundSize =
              backgrounImageSettings.backgroundSize;

            blockWithBackgroundImage.style.backgroundSize = `${
              bbox.width * dragAndDropSettings.widthPercent
            }px ${bbox.height * backgrounImageSettings.heightPercent}px`;
            blockWithBackgroundImage.style.left = `${
              dragAndDropSettings.leftPositionPercent * bbox.width
            }px`;
            blockWithBackgroundImage.style.top = `${
              dragAndDropSettings.topPositionPercent * bbox.height
            }px`;
            blockWithBackgroundImage.style.height = `${
              bbox.height * dragAndDropSettings.heightPercent
            }px`;
            blockWithBackgroundImage.style.width = `${
              bbox.width * dragAndDropSettings.widthPercent
            }px`;

            blockWithBackgroundImage.appendChild(imageContainer);
            blockWrapper.appendChild(blockWithBackgroundImage);
          } else if (
            (!isDragAndDropMode && settingsContext) ||
            !settingsContext
          ) {
            blockWithBackgroundImage.style.backgroundImage =
              backgrounImageSettings.backgroundImage;
            blockWithBackgroundImage.style.backgroundRepeat =
              backgrounImageSettings.backgroundRepeat;
            blockWithBackgroundImage.style.backgroundAttachment =
              backgrounImageSettings.backgroundAttachment;
            blockWithBackgroundImage.style.backgroundPosition =
              backgrounImageSettings.backgroundPosition;
            blockWithBackgroundImage.style.backgroundSize =
              backgrounImageSettings.backgroundSize;

            blockWrapper.appendChild(blockWithBackgroundImage);
          }

          const blockWithPoints = document.createElement("div");

          if (
            settingsContext &&
            backgroundImageSnapToPoint &&
            isDragAndDropMode
          ) {
            blockWithPoints.id = blockWithPointsId;
            blockWithPoints.style.backgroundColor = "transparent";
            blockWithPoints.style.overflow = "visible";
            blockWithPoints.style.height = svgHeight + "px";
            blockWithPoints.style.width = svgWidth + "px";
            blockWithPoints.style.position = "absolute";
            blockWithPoints.style.zIndex = 86;

            blockWrapper.appendChild(blockWithPoints);
          }

          elementParent.appendChild(blockWrapper);

          const pointsMap = L.map(blockWithPoints, defaultMapOptions);
          L.featureGroup(pointsOfMap).addTo(pointsMap);
          pointsMap.fitBounds(minimalBounds);
        }

        return () => {
          map.remove();
        };
      }}
      onRenderError={onRenderError}
    />
  );
};

export default LeafletChoropleth;
