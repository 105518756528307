var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var OI;$CLJS.MI=function(a){return $CLJS.n($CLJS.pB($CLJS.rd,$CLJS.qd,$CLJS.Xk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.NI=function(a,b){return $CLJS.Me($CLJS.zd,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(K,S,X,T){return function(){for(var da=C;;){var oa=$CLJS.y(da);if(oa){var Ma=oa,sb=$CLJS.z(Ma);if(oa=$CLJS.y(function(Qa,Xa,Ja,Ua,Ta,mb,dc,Kd){return function Rh(gd){return new $CLJS.ie(null,function(qz,Il,Un,zr,R_,Mv){return function(){for(;;){var Nv=
$CLJS.y(gd);if(Nv){if($CLJS.vd(Nv)){var Ov=$CLJS.ic(Nv),rz=$CLJS.D(Ov),Ek=$CLJS.le(rz);a:for(var Ar=0;;)if(Ar<rz){var gt=$CLJS.hd(Ov,Ar);gt=$CLJS.E.g(Mv,$CLJS.ZD)||$CLJS.E.g(Un,$CLJS.ZD)||$CLJS.AE(Mv,gt)&&$CLJS.AE(Un,gt);Ek.add(gt);Ar+=1}else{Ov=!0;break a}return Ov?$CLJS.oe($CLJS.qe(Ek),Rh($CLJS.jc(Nv))):$CLJS.oe($CLJS.qe(Ek),null)}Ek=$CLJS.z(Nv);return $CLJS.ae($CLJS.E.g(Mv,$CLJS.ZD)||$CLJS.E.g(Un,$CLJS.ZD)||$CLJS.AE(Mv,Ek)&&$CLJS.AE(Un,Ek),Rh($CLJS.Hc(Nv)))}return null}}}(Qa,Xa,Ja,Ua,Ta,mb,dc,
Kd),null,null)}}(da,K,sb,Ma,oa,S,X,T)($CLJS.kE)))return $CLJS.bf.g(oa,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.MI($CLJS.MD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.MI($CLJS.MD(a)))}())};
OI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.Ne.j($CLJS.wE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.OD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.hl,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.NI($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.PI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.QI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.RI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var SI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,$CLJS.dr],null)),TI=null,UI=0,VI=0;;)if(VI<UI){var Kia=TI.X(null,VI);$CLJS.zE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));VI+=1}else{var WI=$CLJS.y(SI);if(WI){var XI=WI;if($CLJS.vd(XI)){var YI=$CLJS.ic(XI),Lia=$CLJS.jc(XI),
Mia=YI,Nia=$CLJS.D(YI);SI=Lia;TI=Mia;UI=Nia}else{var Oia=$CLJS.z(XI);$CLJS.zE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)],null)],null)],null)]));SI=$CLJS.B(XI);TI=null;UI=0}VI=0}else break}$CLJS.xE($CLJS.fr,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XD],null)]));
for(var ZI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.AF],null)),$I=null,aJ=0,bJ=0;;)if(bJ<aJ){var Pia=$I.X(null,bJ);$CLJS.zE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)],null)],null)]));bJ+=1}else{var cJ=$CLJS.y(ZI);if(cJ){var dJ=cJ;if($CLJS.vd(dJ)){var eJ=$CLJS.ic(dJ),Qia=$CLJS.jc(dJ),
Ria=eJ,Sia=$CLJS.D(eJ);ZI=Qia;$I=Ria;aJ=Sia}else{var Tia=$CLJS.z(dJ);$CLJS.zE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ok,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bE],null)],null)],null)],null)]));ZI=$CLJS.B(dJ);$I=null;aJ=0}bJ=0}else break}
for(var fJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dq,$CLJS.Fq,$CLJS.zq,$CLJS.Bq],null)),gJ=null,hJ=0,iJ=0;;)if(iJ<hJ){var Uia=gJ.X(null,iJ);$CLJS.vE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));iJ+=1}else{var jJ=$CLJS.y(fJ);if(jJ){var kJ=jJ;if($CLJS.vd(kJ)){var lJ=$CLJS.ic(kJ),Via=$CLJS.jc(kJ),Wia=lJ,Xia=$CLJS.D(lJ);fJ=Via;gJ=Wia;hJ=Xia}else{var Yia=
$CLJS.z(kJ);$CLJS.vE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));fJ=$CLJS.B(kJ);gJ=null;hJ=0}iJ=0}else break}
$CLJS.vE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.ME,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));
$CLJS.vE.l(OI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.SE,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.dE],null)]));
for(var mJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,$CLJS.XE],null)),nJ=null,oJ=0,pJ=0;;)if(pJ<oJ){var Zia=nJ.X(null,pJ);$CLJS.xE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));pJ+=1}else{var qJ=$CLJS.y(mJ);if(qJ){var rJ=qJ;if($CLJS.vd(rJ)){var sJ=$CLJS.ic(rJ),$ia=$CLJS.jc(rJ),aja=sJ,bja=$CLJS.D(sJ);mJ=$ia;nJ=aja;oJ=bja}else{var cja=$CLJS.z(rJ);$CLJS.xE(cja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));mJ=$CLJS.B(rJ);
nJ=null;oJ=0}pJ=0}else break}
for(var tJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,$CLJS.UE],null)),uJ=null,vJ=0,wJ=0;;)if(wJ<vJ){var dja=uJ.X(null,wJ);$CLJS.xE(dja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));wJ+=1}else{var xJ=$CLJS.y(tJ);if(xJ){var yJ=xJ;if($CLJS.vd(yJ)){var zJ=$CLJS.ic(yJ),eja=$CLJS.jc(yJ),fja=zJ,gja=$CLJS.D(zJ);tJ=eja;uJ=fja;vJ=gja}else{var hja=$CLJS.z(yJ);$CLJS.xE(hja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YD],null)]));tJ=$CLJS.B(yJ);
uJ=null;vJ=0}wJ=0}else break}
for(var AJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null),BJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.bF,$CLJS.vF,$CLJS.jF,$CLJS.cF],null)),CJ=null,DJ=0,EJ=0;;)if(EJ<DJ){var FJ=CJ.X(null,EJ);$CLJS.JF.v(FJ,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,FJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,AJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null)],null));EJ+=1}else{var GJ=$CLJS.y(BJ);if(GJ){var HJ=GJ;if($CLJS.vd(HJ)){var IJ=$CLJS.ic(HJ),ija=$CLJS.jc(HJ),jja=IJ,kja=$CLJS.D(IJ);BJ=ija;CJ=jja;DJ=kja}else{var JJ=$CLJS.z(HJ);$CLJS.JF.v(JJ,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,JJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,AJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.gE],null)],null));BJ=$CLJS.B(HJ);CJ=null;DJ=0}EJ=0}else break}
$CLJS.JF.v($CLJS.cB,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.vD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ar,$CLJS.Qu,$CLJS.Vw,$CLJS.RE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.jE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aG],null)],null));$CLJS.JF.v($CLJS.IE,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.IE],null),$CLJS.vD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,$CLJS.hD,$CLJS.qD],null)],null));
$CLJS.Y($CLJS.PI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gq,$CLJS.QI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ar,$CLJS.Gq,$CLJS.AF,$CLJS.SE,$CLJS.ME,$CLJS.Dq,$CLJS.zq,$CLJS.Fq,$CLJS.Bq,$CLJS.LE,$CLJS.XE,$CLJS.NE,$CLJS.UE,$CLJS.jF,$CLJS.cF,$CLJS.bF,$CLJS.vF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RI,$CLJS.Gi],null)],null));